import PropTypes from 'prop-types'
import React from 'react'
import portrait from '../images/websiteportrait.png'
import quickfit from '../images/quickfitlogo.png'
import csaim from '../images/csaimlogo.png'
import tempestdesigns from '../images/tempestdesignslogo.png'
import utbwi from '../images/utbwi.png'
import parser from '../images/parser.png'
import resume from '../images/AlbertChoResume-2.pdf'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Who am I?</h2>
          <span className="image main">
            <img src={portrait} alt="" />
            
          </span>
          <p>
            Hey! I'm Albert, a 5-year master's student currently studying computer science at the University of Texas at Austin. In the past, I have interned
            at companies like Visa and Nordstrom to tackle important projects that you can see on their websites today, such as the ReadyLink and ATM Locators
            or the notification system! I have also done my share of robotics research at UT and computer science instructing at Juni Learning.
            Moving on, my engineering interests consist of Full Stack Development, Application Development, and Machine Learning applications. Also, a quick fun
            fact about me is that I am consistently Top 500 Radiant in a game called VALORANT by Riot Games!
          </p>
          <p>
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Resume</h2>
          
            <iframe src={resume} width="750" height="1000"></iframe>
          
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form action="https://getform.io/f/7b639e1d-0a40-49e3-a637-de882f36cc67" method="POST">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://github.com/albertchokor"
                className="icon fa-github"
                target="_blank"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
